div#google_translate_element {
  order: 2;
}
@media (max-width: map-get($breakpoints, lg)) {
  .pageHeader .headerContent {
    margin-bottom: 0;
    justify-content: space-between;
  }
}
@media (max-width: map-get($breakpoints, md)) {
  div#google_translate_element {
    order: 4;
  }
}
.mainCallToAction .cta-button {
  height: 40px;
}
.carousel ul.carouselControls {
  opacity: 0;
  @media (max-width: map-get($breakpoints, lg)) {
    opacity: 0;
  }
}
.carousel:hover ul.carouselControls {
  opacity: 1;
}
.homeIntroWrapper {
  //font-size: 1em;
  padding-top: 5rem;
  h3 {
    color: white;
  }
  @media (max-width: map-get($breakpoints, lg)) {
    padding-top: 2rem;
    background-color: white;
    color: $brand-blue;
    h3 {
      color: $brand-blue;
    }
  }
}
.homeFeed:nth-child(even):not(.CasestudiesFeed) {
  background-color: $brand-blue;
  .feedsTitle {
    color: white;
  }
  .feedItem {
    .feedItemDetailsWrapper {
      .feedTitle a {
        color: white;
      }
      > p {
        color: white;
      }
    }
  }
}
.homeFeed:last-child {
  padding-bottom: 0;
}

@media (min-width: map-get($breakpoints, lg)) {

  .CasestudiesFeed {
    padding: 0;
    .feedsTitle {
      display: none;
    }
    .feedList {
      max-width: 100%;;
      .feedItem {
        margin: 0;
        .feedItemDetailsWrapWrapper {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          max-width: $container-max-width;
          margin: auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .feedItemDetailsWrapper {
          margin: 0;
          position: relative;
          padding: 2rem!important;  
          max-width: 415px;
          // a {
          //   color: $link-colour;
          // }
        }
        .readMore {
          display: table;
          margin: 1rem 0;
        }
      }
    }
  }

}
.GetsupportFeed {
  background-color: $brand-blue;
  padding-top: 0;
  .feedsTitle {
    display: none;
  }
  li {
    border-right: 1px solid rgba(255,255,255,.3);
    margin: 0;
    &:hover {
      border-color: rgba(255,255,255,.3);
    }
    .feedItemDetailsWrapper {
      padding: ($spacer * 3) ($spacer * 2)!important;
      h3 {
        padding-left: 80px;
        a {
          color: white;
        }
        position: relative;
        &:before {
          content: '\f18e';
          font-family: 'FontAwesome';
          color: white;
          font-size: 60px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      p {
        color: white!important;
        padding-left: 80px!important;
      }
      div a {
        color: white;
        padding-left: 80px;
        margin-top: $spacer;
        display: flex;
        font-weight: bold;
      }
    }
    &:last-child {
      border: 0;
    }
  }
}
.homefeaturecategory-homeboximpactstats {

  .homeImpactWrapper {
    padding: 0;

    > h2 {
      padding: 0 15px;
    }
  }
  tr {
    border-right: 1px solid rgba(255,255,255,.3);
    border-left: 1px solid rgba(255,255,255,.3);
    border-right: 1px solid $brand-blue;
    border-left: 1px solid $brand-blue;
    margin: 0;
    &:first-child, &:last-child {
      border: 0;
    }
    td h2 {
      margin-bottom: .5em!important;
    }
  }
  table {
    margin: 0;
  }
  @media (max-width: map-get($breakpoints, lg)) {
    background-color: $grey-lightest;
  }
  @media (max-width: map-get($breakpoints, md)) {
    .homeImpactWrapper > h2 {
      margin-bottom: 1rem;
    }
    tr {
      padding: 20px;
      text-align: center;
    }
  }
}
.homefeaturecategory-homeboxquickgiving .homepage-quickgiving--copy {
  @media (min-width: map-get($breakpoints, lg)) {
    display: flex;
    align-items: center;
    h2 {
      margin-right: 20px;
    }
  }
}
.UpcomingtrainingFeed .cta-button {
  display: table;
  margin: auto;
}
.footerBox {
  &[class*="ewsletter"] {
    grid-column: 1 / span 16;
    margin: 0;
    .cta-button {
      background-color: $brand-orange;
      font-size: 1.25em;
      &:hover {
        background-color: darken($brand-orange, 10%);
      }
    }
  }
  a.cta-button[href*=donate] {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 90px;
    background-color: $brand-orange;
    height: 90px;
    border-radius: 90px;
    &:hover {
      transform: scale(1.075);
      background-color: darken($brand-orange, 10%);
    }
  }
  img {
    max-height: 150px;
    margin: 30px;
  }
  &[class*="credits"] {
    margin-top: 0;
  }
  .footerLinks {
    margin: ($spacer * 1.5) 0;
    li {
      display: inline-flex;
      padding: 0 10px;
      border-left: 1px solid rgba(255,255,255,.75);
      &:first-child {
        border: none;
      }
    }
  }
}

h2.associatedTitle a {
  color: $brand-blue;
}

// 620543 Remove register form from ipsea-legal-resources category page when not logged in
.ipsea-legal-resources {
  .formQuestion.firstName, .formQuestion.lastName, .formQuestion.email, button#groupSignUpBtn {
    display: none;
  }
}